<template>
  <div class="header-container">
    <div class="text-center">
      <label class="text-uppercase">Realiza estos 3 pasos para agendar tu hora</label>
      <p>Ingresa tus datos y preferencias</p>
    </div>
    <div class="step-container">
      <div class="line" :class="{ 'fill-line': step > 1 }"></div>
      <div class="second-line line" :class="{ 'fill-line': step > 2 }"></div>
      <div class="first-circle step" :class="{ 'fill-circle': step >= 1 }">
        <label class="step-number">Paso 1</label>
        <label class="text-step">Selecciona <br />al menos un campo</label>
      </div>
      <div class="second-circle step" :class="{ 'fill-circle': step >= 2 }">
        <label class="step-number">Paso 2</label>
        <label class="text-step">Escoge tu hora</label>
      </div>
      <div class="third-circle step" :class="{ 'fill-circle': step >= 3 }">
        <label class="step-number">Paso 3</label>
        <label class="text-step">Ingresa tus datos</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step",
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.header-container {
  position: relative;
  margin-bottom: 15px;
}
.step-container {
  position: relative;
  margin-top: 7%;
  margin-bottom: 20%;
}
.step {
  position: absolute;
  background: #b7b7b7;
  border-radius: 50%;
  padding: 12px;
  text-align: center;
}
.second-circle {
  left: 48%;
}
.third-circle {
  left: 94%;
}

.line {
  position: absolute;
  padding: 5px;
  width: 47%;
  left: 1%;
  top: 6px;
  background: #b7b7b7;
}
.step-number {
  position: absolute;
  top: -29px;
  width: 50px;
  left: -7px;
  color: gray;
  font-size: 15px;
}
.fill-line {
  background: #5a9e5aa3;
}
.fill-circle {
  background: green;
}
.second-line {
  left: 48%;
}
.text-step {
  position: absolute;
  color: gray;
  width: 150px;
  top: 30px;
  left: -60px;
  font-size: 15px;
}
.text-uppercase {
  font-weight: 800;
  font-size: 20px;
}
@media (max-width: 768px) {
  .step-container {
    margin-top: 8%;
    margin-bottom: 25%;
  }
}
@media (max-width: 425px) {
  .text-step {
    width: 100px;
  }
  .first-circle.step > .text-step {
    left: -30px;
  }
  .second-circle.step > .text-step {
    left: -40px;
  }
  .third-circle.step > .text-step {
    left: -50px;
  }
  .step-container {
    margin-top: 13%;
    margin-bottom: 50%;
  }
}
</style>
